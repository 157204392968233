<template>
  <div id="app">
    <div
      v-if="uiFlags.isWrapperVisible"
      :class="[uiFlags.uiLayoutDefinitions.bgColor, uiFlags.uiLayoutDefinitions.isWideLayout ? 'container-full' : 'container-narrow']"
    >
      <div
        :class="{
          'main-scroll': isScrollingFromInnerContainer,
          'overflow-auto': isScrollingFromInnerContainer,
          'h-100': isScrollingFromInnerContainer,
        }"
      >
        <SlideTransition v-if="$store.getters.isLoaded && uiFlags.isNavigationBarVisible">
          <NavigationBar />
        </SlideTransition>
        <SlideTransition>
          <StepsProgress v-if="shouldShowProgressBar" />
        </SlideTransition>
        <transition
          :name="$route.meta.transition ? $route.meta.transition.name : ''"
          :mode="$route.meta.transition ? $route.meta.transition.mode : ''"
        >
          <keep-alive :exclude="excludedCacheRoutes">
            <router-view
              :key="$route.fullPath"
              :class="{
                'main-content': !isMapRoute,
                'main-content-map': isMapRoute,
                'mt-n12': uiFlags.uiLayoutDefinitions.isRouteWithHeaderPicture,
              }"
            />
          </keep-alive>
        </transition>
      </div>
      <transition
        :name="$route.meta.transition ? $route.meta.transition.name : ''"
        :mode="$route.meta.transition ? $route.meta.transition.mode : ''"
      >
        <div
          id="sd-container-bottom-portal-wrapper"
          :key="$route.fullPath"
          :class="{ animation: $route.params.animate }"
        >
          <PortalTarget
            id="sd-container-bottom-wrapper"
            name="sd-container-bottom"
          />
        </div>
      </transition>
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="uiFlags.isLoading"
          id="loader-container"
        >
          <SdSpinner />
        </div>
      </transition>
      <SdDialog ref="dialog" />
      <SdToast ref="toast" />
      <PortalTarget
        name="sd-modal"
        slim
      />
      <Footer v-if="uiFlags.uiLayoutDefinitions.isWideLayout" />
    </div>
    <router-view
      v-else
      :key="$route.fullPath"
    />
    <Footer v-if="!uiFlags.uiLayoutDefinitions.isWideLayout" />
    <LightBox />
  </div>
</template>
<script>
import {
  computed, defineComponent, inject, onMounted, provide, reactive,
} from '@vue/composition-api';
import { Wormhole } from 'portal-vue';
import NavigationBar from './components/NavigationBar';
import StepsProgress from './components/StepsProgress';
import { isLoading } from './services/loading';
import Footer from './components/Footer';
import SdSpinner from './components/SdSpinner';
import InquiryStatus from './constants/InquiryStatus';
import SdDialog from './components/SdDialog';
import SdToast from './components/SdToast';
import SlideTransition from './components/SlideTransition';
import LightBox from './components/LightBox';
import routeUtils from './utils/routeUtils';
import RouteNames from './constants/RouteNames';

export default defineComponent({
  components: {
    Footer,
    SlideTransition,
    SdToast,
    SdDialog,
    SdSpinner,
    StepsProgress,
    NavigationBar,
    LightBox,
  },
  setup(props, context) {
    const isMobile = inject('isMobile');
    const { $store } = context.root;
    const excludedCacheRoutes = ['Inquiry', 'Reschedule', 'QualificationCriteria', 'Unqualified', 'InspectionTenant', 'ChooseInspectionWindow'];

    onMounted(mounted);

    const uiLayoutDefinitions = computed(() => routeUtils.computeIsWideLayout({
      routeName: context.root.$route.name,
      $store,
      isMobile,
    }));

    const uiFlags = reactive({
      isMobile,
      isLoading,
      isNavigationBarVisible: computed(computeIsNavigationBarVisible),
      isWrapperVisible: computed(computeIsWrapperVisible),
      isContainerBottomVisible: computed(computeIsContainerBottomVisible),
      uiLayoutDefinitions,
    });

    const isScrollingFromInnerContainer = computed(() => uiFlags.isMobile || !uiFlags.uiLayoutDefinitions.isWideLayout);
    const isMapRoute = computed(() => context.root.$route?.name?.includes('.map'));
    // Progress bar is disabled hardcoded - should be determined by product if we want to re-enable it
    const shouldShowProgressBar = false && $store.getters.isLoaded;

    return {
      uiFlags,
      excludedCacheRoutes,
      shouldShowProgressBar,
      isScrollingFromInnerContainer,
      isMapRoute,
    };

    function mounted() {
      context.root.$dialog = context.refs.dialog;
      context.root.$toast = context.refs.toast;
      provide('$toast', context.refs.toast);
    }

    function computeIsNavigationBarVisible() {
      return (
        $store.getters.isLoaded
        && context.root.$route.name !== RouteNames.INQUIRY_UNQUALIFIED
        && !context.root.$route.name.includes('.map')
        && $store.getters.getProperty('inquiry.status') !== InquiryStatus.COMPLETED
      );
    }

    function computeIsWrapperVisible() {
      return context.root.$route.name !== RouteNames.VIDEO_MEETING;
    }

    function computeIsContainerBottomVisible() {
      return Wormhole.hasContentFor('sd-container-bottom');
    }
  },
});
</script>

<style lang="scss" scoped>
#loader-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

#sd-container-bottom-portal-wrapper {
  z-index: 9997;
  margin-top: auto;

  > div {
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
}

.main-content-map {
  height: inherit;
}

// Grid container
.container-narrow {
  @include make-container();

  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 540px;
  height: calc(100% - $footer-height);
  min-height: auto;
  margin-top: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: $border-radius;

  @include media-breakpoint-up(md) {
    height: calc(100% - $footer-height - $top-narrow-container-spacing);
    margin-top: $top-narrow-container-spacing;
  }

  .main-content {
    padding-bottom: $top-narrow-container-spacing;
  }
}

.container-full {
  @include make-container();

  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  padding: 0;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;

  @include media-breakpoint-between(xs, md) {
    max-width: 100% !important;
  }

  @include media-breakpoint-up(xl) {
    max-width: map-get($container-max-widths, 'xl') !important;
  }
}
</style>
